import { ColorFamily, ColorFamilyEnum_Enum } from '@graphql-types@';
import classNames from 'classnames';
import { sidebarWidthAtom } from 'components/Panels/SidePanel';
import {
  CONTACTPANEL_WIDTH,
  TODO_HEADER_SECTION_HEIGHT,
} from 'components/Panels/utils';
import { optimisticEventsFamily } from 'hooks/events/eventAtoms';
import { useDefaultEventColor } from 'hooks/usePreferences';
import { useAtomCallback, useAtomValue } from 'jotai/utils';
import React, { useCallback, useEffect, useMemo } from 'react';
import { IGridEvent } from 'types/events';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { TodoItem } from './TodoItem';
import { dragEventAtom, dragOverCategoryAtom } from './todosAtoms';
import { TodoCategoryT } from './types';
import { colorFamilyToColor, useIsMouseInRange } from './utils';
const GRID_TIMESTAMPS_WIDTH = 70;

function getGhostColorFamily(
  dragEvent: IGridEvent | null,
  dragOverCategory: TodoCategoryT | null,
  defaultEventColor: ColorFamily
): ColorFamily {
  if (dragOverCategory) {
    return dragOverCategory.colorFamily
      ? colorFamilyToColor(dragOverCategory.colorFamily)
      : defaultEventColor;
  }
  return dragEvent?.colorFamily || defaultEventColor;
}

export function DragFromScheduleGhost() {
  const dragEvent = useAtomValue(dragEventAtom);
  const dragOverCategory = useAtomValue(dragOverCategoryAtom);
  const defaultEventColor = useDefaultEventColor();
  const sidebarWidth = useAtomValue(sidebarWidthAtom);

  const showGhost = useIsMouseInRange({
    x: CONTACTPANEL_WIDTH,
    width: sidebarWidth + GRID_TIMESTAMPS_WIDTH + 6,
    y: TODO_HEADER_SECTION_HEIGHT,
  });

  const colorFamily = getGhostColorFamily(
    dragEvent,
    dragOverCategory,
    defaultEventColor
  );

  const colorEnum = colorFamily.toUpperCase() as ColorFamilyEnum_Enum;

  const colorMap = EVENT_COLOR_MAP[colorFamily];

  const widthStyle = useMemo(
    () => ({
      width: sidebarWidth * 0.85 + 'px',
    }),
    [sidebarWidth]
  );

  const updateEventVisibility = useAtomCallback(
    useCallback((get, set, isVisible: boolean) => {
      const dragEvent = get(dragEventAtom);
      if (dragEvent) {
        set(optimisticEventsFamily(dragEvent?.id), (prevValues) => ({
          ...prevValues,
          hidden: !isVisible,
        }));
      }
    }, [])
  );

  useEffect(() => {
    updateEventVisibility(!showGhost);
  }, [showGhost, updateEventVisibility]);

  if (dragEvent == null || !showGhost) return null;

  return (
    <div
      style={widthStyle}
      className={classNames(
        'pointer-events-none rounded-md px-1',
        colorMap.todoText,
        colorMap.todoDragBackground
      )}
    >
      <TodoItem
        id="ghost"
        categoryId={dragOverCategory?.id || 'none'}
        doneAt={dragEvent.doneAt || undefined}
        label={dragEvent.title}
        colorFamily={colorEnum}
      />
    </div>
  );
}
