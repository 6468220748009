import { ColorFamilyEnum_Enum } from '@graphql-types@';
import classNames from 'classnames';
import GridEvent from 'components/Grid/GridEvent';
import { sidebarWidthAtom } from 'components/Panels/SidePanel';
import {
  CONTACTPANEL_WIDTH,
  GRID_TIMESTAMPS_WIDTH,
  TODO_HEADER_SECTION_HEIGHT,
} from 'components/Panels/utils';
import { gridEventsFamily } from 'hooks/events/eventAtoms';
import { useDefaultEventColor } from 'hooks/usePreferences';
import { useAtomValue } from 'jotai/utils';
import React, { useMemo } from 'react';
import { useWindowSize } from 'react-use';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { DragHandle } from './DragHandle';
import { TodoItem } from './TodoItem';
import {
  dragOverCategoryAtom,
  dragTodoAtom,
  normalizedCategoriesAtom,
} from './todosAtoms';
import { colorFamilyToColor, eventDefaults, useIsMouseInRange } from './utils';

export function DragTodoGhost() {
  const dragTodo = useAtomValue(dragTodoAtom);
  const dragOverCategory = useAtomValue(dragOverCategoryAtom);
  const categories = useAtomValue(normalizedCategoriesAtom);
  const defaultEventColor = useDefaultEventColor();
  const sidebarWidth = useAtomValue(sidebarWidthAtom);
  const draftEvent = useAtomValue(gridEventsFamily('draft'));
  const windowSize = useWindowSize();

  const showGhost = useIsMouseInRange({
    x: CONTACTPANEL_WIDTH,
    y: TODO_HEADER_SECTION_HEIGHT,
  });
  const showEventGhost = useIsMouseInRange({
    x: CONTACTPANEL_WIDTH + sidebarWidth,
    y: TODO_HEADER_SECTION_HEIGHT,
  });

  const widthStyle = {
    top: '-14px',
    width: sidebarWidth * 0.95 + 'px',
    marginLeft: sidebarWidth * 0.8 + 12 + 'px',
  };

  if (!dragTodo || !categories || !(dragTodo.categoryId in categories)) {
    return null;
  }

  const dragTodoCategory = categories[dragTodo.categoryId];

  const categoryColor = dragTodoCategory?.colorFamily
    ? colorFamilyToColor(dragTodoCategory?.colorFamily)
    : undefined;

  const dragOverCategoryColor = dragOverCategory?.colorFamily
    ? colorFamilyToColor(dragOverCategory?.colorFamily)
    : undefined;

  const colorFamily =
    dragOverCategoryColor || categoryColor || defaultEventColor;

  const colorEnum = colorFamily.toUpperCase() as ColorFamilyEnum_Enum;

  const color = colorFamilyToColor(colorEnum);
  const colorMap = EVENT_COLOR_MAP[color];

  if (showEventGhost) {
    const UI_WIDTH = sidebarWidth + CONTACTPANEL_WIDTH + GRID_TIMESTAMPS_WIDTH;
    const SCHEDULE_WIDTH = windowSize.width - UI_WIDTH;
    const EVENT_PADDING = 26;
    const EVENT_WIDTH = SCHEDULE_WIDTH / 7 - EVENT_PADDING;

    return (
      <GridEvent
        style={{
          pointerEvents: 'none',
          width: EVENT_WIDTH + 'px',
          position: 'absolute',
          top: '-12px',
        }}
        previewOnly
        event={{
          ...eventDefaults,
          title: dragTodo.name || 'New scheduled todo',
          colorFamily,
          doneAt: dragTodo.doneAt,
        }}
      />
    );
  }

  if (!showGhost || draftEvent != null) return null;

  return (
    <div
      className={classNames(
        'pointer-events-none relative inline-flex rounded-md',
        colorMap.todoDragBackground,
        colorMap.todoButtonText
      )}
      style={widthStyle}
    >
      <DragHandle
        className={classNames(colorMap.todoButtonText, colorMap.todoButton)}
      />
      <TodoItem
        className="bg-opacity-25 dark:bg-opacity-10"
        id={dragTodo.id}
        colorFamily={colorEnum}
        categoryId={dragTodo.categoryId}
        label={dragTodo.name}
        doneAt={dragTodo.doneAt}
      />
    </div>
  );
}
