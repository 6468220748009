/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable chai-friendly/no-unused-expressions */
import {
  ColorFamily,
  NewEventRsvpEnum,
  NewEventVisibilityEnum,
} from '@graphql-types@';
import classNames from 'classnames';
import { IconExclamationMark } from 'components/Icons/IconExclamationMark';
import IconLockedClosed from 'components/Icons/IconLockClosed';
import Checkbox from 'joy/Checkbox';
import Tooltip from 'joy/Tooltip';
import { DateTime } from 'luxon';
import React, { CSSProperties, useMemo, useState } from 'react';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import {
  NEW_EVENT_PLACEHOLDER_TITLE,
  PRIVATE_EVENT_PLACEHOLDER_TITLE,
} from 'utils/events';
import { getTimeFormat, NormalizedPreferences } from 'utils/format';
import { IGridEvent } from '../../types/events';
import GridResizeHandles from './GridResizeHandles';
import { formatTime, getEventStyle, isEvent } from './utils';

// import { GridEventContextMenu } from './GridEventContextMenu';
import { DropdownItem } from 'joy/Dropdown';
import dynamic from 'next/dynamic';
import { useDefaultEventColor } from 'hooks/usePreferences';

const GridEventContextMenu = dynamic(() => import('./GridEventContextMenu'));

interface Props {
  previewOnly?: boolean;
  event: IGridEvent;
  colorOverride?: ColorFamily;
  selected?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  ui24HourClock?: NormalizedPreferences['ui24HourClock'];
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onContextMenu?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onMouseMove?: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleCheckboxChange?: () => void;
  disableResize?: boolean;
  onResize?(updatedValues: { startAt: DateTime; endAt: DateTime }): void;
  onResizeCancel?(): void;
  onResizeEnd?(): void;
  ctxMenuItems?: DropdownItem[];
  onContextMenuClose?: () => void;
}

// eslint-disable-next-line react/display-name
export default React.memo(
  React.forwardRef(function GridEventComponent(
    {
      event,
      colorOverride,
      style,
      selected,
      previewOnly,
      ui24HourClock = false,
      disabled,
      handleCheckboxChange,
      disableResize,
      onClick,
      onResize,
      onResizeEnd,
      onResizeCancel,
      onContextMenu,
      ctxMenuItems,
      onContextMenuClose,
      ...props
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) {
    const {
      isAllDay,
      title,
      colorFamily: eventColorFamily,
      visibility,
      doneAt,
      startAt,
      endAt,
    } = event;

    const [isEventResizing, setIsEventResizing] = useState(false);

    const isPrivateEvent = visibility === NewEventVisibilityEnum.Private;

    const defaultEventColor = useDefaultEventColor();
    const colorFamily = colorOverride || eventColorFamily || defaultEventColor;
    const colorMap = EVENT_COLOR_MAP[colorFamily];

    const isRSVPUnknown = event.rsvp === NewEventRsvpEnum.Unknown;
    const isRSVPNo = event.rsvp === NewEventRsvpEnum.No;

    const durationMinutes = endAt.diff(startAt, 'minutes').minutes;

    const isShortEvent = previewOnly || isAllDay ? false : durationMinutes < 25;

    const isSmallCardEvent = durationMinutes <= 60;
    const isMediumCardEvent = durationMinutes > 60 && durationMinutes <= 240;
    const isLargeCardEvent = durationMinutes > 240;

    const timeFormatPreference = getTimeFormat(ui24HourClock);

    const formattedStartAt = isAllDay
      ? null
      : formatTime(timeFormatPreference, startAt);

    const formattedEndAt = isAllDay
      ? null
      : formatTime(timeFormatPreference, endAt);

    const shouldDisplayEventTime =
      !!formattedStartAt && durationMinutes >= 45 && !previewOnly;

    const showCheckbox = event.belongsToUserCalendar;

    const eventStyle = useMemo(() => {
      if (style) return style;

      return getEventStyle({
        event: {
          ...event,
          startAt: event.startAt,
          endAt: event.endAt,
        },
        dayIndex: event.dayIndex,
        previewOnly,
      });
    }, [style, event, previewOnly]);

    const hasContextMenu =
      !!ctxMenuItems &&
      ctxMenuItems.length > 0 &&
      onContextMenuClose &&
      onContextMenu;

    return (
      <div
        ref={ref}
        tabIndex={-1}
        aria-label={event.title}
        aria-selected={selected}
        // Remove some of these cy- data attributes...
        data-cy-title={event.title}
        data-cy-id={event.id}
        data-cy-timestart={event.startAt.toFormat('HH:mm')}
        data-cy-timeend={event.endAt.toFormat('HH:mm')}
        data-dd-action-name="click-grid-event"
        className={classNames(
          'bg-background group relative flex transition-opacity',
          {
            'cursor-pointer': !isEventResizing,
            'rounded-lg': !isShortEvent,
            'rounded-md': isShortEvent,
            'pointer-events-none': disabled,
          }
        )}
        style={eventStyle}
        {...props}
        role="gridcell"
        onClick={onClick}
        onContextMenu={onContextMenu}
      >
        <div
          className={classNames(
            'border-background flex w-full items-start truncate border pl-1 transition-all',
            {
              'active:scale-[0.98]': isSmallCardEvent,
              'active:scale-[0.985]': isMediumCardEvent,
              'active:scale-[0.99]': isLargeCardEvent,
              [colorMap.scheduleEventSelected]: selected,
              [colorMap.scheduleEventBase]: !selected,
              'bg-striped': isRSVPUnknown && !selected,
              'bg-striped-selected': isRSVPUnknown && selected,
              'rounded-lg py-0.5': !isShortEvent,
              'rounded-md': isShortEvent,
              'opacity-50':
                disabled ||
                (isEvent(event) &&
                  (event.isOwnEvent || event.isSelfAsAttendee) &&
                  (doneAt || isRSVPNo) &&
                  !selected),
              'dark:border-gray-700': previewOnly,
            }
          )}
        >
          {showCheckbox && (
            <Checkbox
              preventDragEvents
              className={classNames(
                'relative z-20 group-hover:scale-100 group-hover:opacity-100',
                {
                  'w-0 scale-75 opacity-0': !selected && !isEventResizing,
                  'scale-100 opacity-100': selected || isEventResizing,
                  'mt-px group-hover:w-[7px]': isShortEvent,
                  'mt-0.5 group-hover:w-[13px]': !isShortEvent,
                  'w-[7px]': isShortEvent && (selected || isEventResizing),
                  'w-[13px]': !isShortEvent && (selected || isEventResizing),
                }
              )}
              value={Boolean(doneAt)}
              colorMap={colorMap}
              size={isShortEvent ? 7 : 13}
              variant={selected ? 'selectedevent' : 'event'}
              disabled={event.rsvp === NewEventRsvpEnum.No}
              onChange={handleCheckboxChange}
            />
          )}

          {hasContextMenu && (
            <GridEventContextMenu
              ctxMenuItems={ctxMenuItems}
              event={event}
              onClose={onContextMenuClose}
            />
          )}

          <div
            className={classNames(
              'flex flex-1 flex-col items-start truncate transition-margin',
              {
                'group-hover:ml-1': showCheckbox || (selected && showCheckbox),
                'ml-1': selected && showCheckbox,
                'ml-0.5': !selected && showCheckbox,
              }
            )}
          >
            <small
              className={classNames('truncate font-medium', {
                'text-s leading-[17px]': !isShortEvent,
                'text-[10px] leading-[10px]': isShortEvent,
                'line-through': isRSVPNo,
              })}
            >
              {isPrivateEvent && event?.belongsToUserCalendar === false
                ? PRIVATE_EVENT_PLACEHOLDER_TITLE
                : title || NEW_EVENT_PLACEHOLDER_TITLE}
            </small>
            {shouldDisplayEventTime && (
              <small
                className={classNames(
                  'min-w-[30px] text-left font-medium leading-none opacity-60',
                  {
                    'pt-px text-[11px]': !isShortEvent,
                    'text-[9px]': isShortEvent,
                    '-mt-0.5': durationMinutes <= 45,
                  }
                )}
              >
                {isEventResizing
                  ? `${formattedStartAt} – ${formattedEndAt}`
                  : formattedStartAt}
              </small>
            )}
          </div>
          {isEvent(event) && event.allOtherGuestsDeclined ? (
            <Tooltip
              content="Everyone else has declined"
              placement="bottom-center"
            >
              <span>
                {/* span is used to support Tooltip around the icon */}
                <IconExclamationMark
                  className={classNames('z-100 mx-1 opacity-60', {
                    'mt-px h-[7px] w-[7px]': isShortEvent,
                    'mt-0.5 h-3 w-3': !isShortEvent,
                  })}
                />
              </span>
            </Tooltip>
          ) : (
            isPrivateEvent && (
              <IconLockedClosed
                className={classNames('mx-1  opacity-60', {
                  'mt-px h-[7px] w-[7px]': isShortEvent,
                  'mt-0.5 h-3 w-3': !isShortEvent,
                })}
              />
            )
          )}
        </div>

        {!isAllDay && event.canEdit && !disableResize && onResize && (
          <GridResizeHandles
            startAt={event.startAt}
            endAt={event.endAt}
            onResize={onResize}
            onResizeEnd={onResizeEnd}
            onResizeCancel={onResizeCancel}
            disabled={isShortEvent}
            onIsResizingChange={setIsEventResizing}
          />
        )}
      </div>
    );
  })
);
