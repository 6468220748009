import classNames from 'classnames';
import IconRecurrence from 'components/Icons/IconRecurrence';
import { capitalize } from 'components/Settings/utils';
import Button from 'joy/Button';
import Dropdown, { DropdownItem } from 'joy/Dropdown';
import Tooltip from 'joy/Tooltip';
import numeral from 'numeral';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import RRule, { rrulestr } from 'rrule';
import { IGridEvent } from 'types/events';
import { colorOrDefault, EVENT_COLOR_MAP } from 'utils/eventColors';
import { formatOrdinal } from 'utils/time';
import { useDefaultEventColor } from 'hooks/usePreferences';

type EventRecurrenceProps = {
  event: IGridEvent;
  onChange: (rrule: string[]) => void;
};

interface RecurrenceRuleChoiceTitle {
  type: 'title';
  value: string;
}

export interface RecurrenceRuleChoiceItem {
  type: 'option';
  value: string;
  rule: RRule;
  triggersSpinEffect?: boolean;
}

export type RecurrenceRuleChoice =
  | RecurrenceRuleChoiceTitle
  | RecurrenceRuleChoiceItem;

export const getRecurrenceRuleChoices = (
  event: IGridEvent
): RecurrenceRuleChoice[] => {
  const repeatOnEventDayrule = getRRuleForEveryWeekOnDay(event.startAt);
  const repeatOnEveryOtherWeekRule = getRRuleForEveryOtherWeekOnDay(
    event.startAt
  );
  const repeatOnEveryMonthOnDayRule = getRRuleForEveryMonthOnDay(event.startAt);
  const weeksDiff = Math.ceil(
    event.startAt.diff(event.startAt.startOf('month'), 'weeks').weeks
  );
  const repeatOnEveryYearOnDayRule = getRRuleForEveryYearOnDay(event.startAt);

  return [
    { type: 'title', value: 'Repeat Daily' },
    {
      type: 'option',
      value: capitalize(rruleRepeatsDaily.toText()),
      rule: rruleRepeatsDaily,
      triggersSpinEffect: true,
    },
    {
      type: 'option',
      value: 'Every weekday',
      rule: rruleRepeatsWeekDays,
      triggersSpinEffect: true,
    },
    {
      type: 'option',
      value: `Every ${event.startAt.toFormat('cccc')}`,
      rule: repeatOnEventDayrule,
      triggersSpinEffect: true,
    },
    {
      type: 'option',
      value: `Every other ${event.startAt.toFormat('cccc')}`,
      rule: repeatOnEveryOtherWeekRule,
    },
    { type: 'title', value: 'Repeat Monthly' },
    {
      type: 'option',
      value: `Monthly on the ${formatOrdinal(event.startAt.day)}`,
      rule: repeatOnEveryMonthOnDayRule,
    },
    {
      type: 'option',
      value: `Monthly on the ${numeral(weeksDiff).format(
        '0o'
      )} ${event.startAt.toFormat('cccc')}`,
      rule: repeatOnEveryMonthOnDayRule,
    },
    { type: 'title', value: 'Repeat Yearly' },
    {
      type: 'option',
      value: `Every year on ${event.startAt.toFormat('MMMM')} ${formatOrdinal(
        event.startAt.day
      )}`,
      rule: repeatOnEveryYearOnDayRule,
    },
  ];
};

export default function EventRecurrence({
  event,
  onChange: setEventRecurring,
}: EventRecurrenceProps): JSX.Element | null {
  const defaultEventColor = useDefaultEventColor();
  const [recurrenceRuleText, setRecurrentRuleText] = useState(
    recurrenceRulesToRRule(event.recurrenceRules).toText()
  );
  const [spinEffect, setSpinEffect] = useState(false);

  const triggerSpinEffect = () => {
    setSpinEffect(true);

    setTimeout(() => {
      setSpinEffect(false);
    }, 1000);
  };

  useEffect(() => {
    if (event.recurrenceRules?.length) {
      setRecurrentRuleText(
        recurrenceRulesToRRule(event.recurrenceRules).toText()
      );
    } else {
      setRecurrentRuleText(
        recurrenceRulesToRRule(event.recurrenceRules).toString() // .toText() will return "every year" for an empty rule
      );
    }
  }, [event.recurrenceRules]);

  const colorFamily = event.colorFamily || defaultEventColor;
  const colorItem = EVENT_COLOR_MAP[colorFamily];

  if (event.canEdit) {
    const getMenuItems = (): DropdownItem[] => {
      const defaultItems: DropdownItem[] = [];

      defaultItems.push(
        ...getRecurrenceRuleChoices(event).map((rr) => {
          if (rr.type === 'title') {
            return rr;
          } else {
            return {
              type: rr.type,
              value: rr.value,
              selected: recurrenceRuleText === rr.rule.toText(),
              onSelect: () => {
                setEventRecurring([rr.rule.toString()]);
                setRecurrentRuleText(rr.rule.toText());

                if (rr.triggersSpinEffect) {
                  triggerSpinEffect();
                }
              },
            };
          }
        })
      );

      const isDefaultRule = defaultItems.some(
        (item) => item.type === 'option' && item.selected
      );

      return [
        ...defaultItems,
        {
          type: 'option',
          value: capitalize(recurrenceRuleText),
          selected: recurrenceRuleText !== undefined,
          hidden: !recurrenceRuleText || isDefaultRule,
        },
        {
          type: 'separator',
        },
        {
          type: 'option',
          value: `Don't repeat`,
          hidden: !recurrenceRuleText,
          onSelect: () => {
            setEventRecurring([]);
            setRecurrentRuleText('');
            triggerSpinEffect();
          },
        },
      ];
    };

    const dropdownTooltipText = recurrenceRuleText
      ? `Repeats ${recurrenceRuleText}`
      : 'Change recurrence';

    return (
      <Dropdown items={getMenuItems} menuClassName="max-h-60">
        <Tooltip content={dropdownTooltipText}>
          <Button
            variant="popoverIcon"
            className={classNames({
              [`${colorItem.buttonAllDayEnabled} ${colorItem.text} ${colorItem.buttonAllDayEnabledHover}`]:
                recurrenceRuleText,
            })}
          >
            <IconRecurrence
              className={classNames({
                'animate-spinOnce': spinEffect === true,
              })}
            />
          </Button>
        </Tooltip>
      </Dropdown>
    );
  }

  const readOnyTooltipText = recurrenceRuleText
    ? `Repeats ${recurrenceRuleText}`
    : "Doesn't repeat";

  return (
    <Tooltip content={readOnyTooltipText}>
      <div
        className={classNames(
          'text-secondary flex h-6 w-6 items-center justify-center rounded-md p-1',
          {
            [`${colorItem.buttonAllDayEnabled} ${colorItem.text}`]:
              recurrenceRuleText,
          }
        )}
      >
        <IconRecurrence />
      </div>
    </Tooltip>
  );
}

const rruleRepeatsDaily = RRule.fromText('Every day');
const rruleRepeatsWeekDays = RRule.fromText('Every weekdays');

function getRRuleForEveryWeekOnDay(startAt: DateTime) {
  switch (startAt.toFormat('cccc').toLowerCase()) {
    case 'monday':
      return new RRule({ freq: RRule.WEEKLY, byweekday: [RRule.MO] });
    case 'tuesday':
      return new RRule({ freq: RRule.WEEKLY, byweekday: [RRule.TU] });
    case 'wednesday':
      return new RRule({ freq: RRule.WEEKLY, byweekday: [RRule.WE] });
    case 'thursday':
      return new RRule({ freq: RRule.WEEKLY, byweekday: [RRule.TH] });
    case 'friday':
      return new RRule({ freq: RRule.WEEKLY, byweekday: [RRule.FR] });
    case 'saturday':
      return new RRule({ freq: RRule.WEEKLY, byweekday: [RRule.SA] });
    default:
      return new RRule({ freq: RRule.WEEKLY, byweekday: [RRule.SU] });
  }
}

function getRRuleForEveryOtherWeekOnDay(startAt: DateTime) {
  const day = startAt.toFormat('cccc');
  return RRule.fromText(`Every 2 weeks on ${day}`);
}

function getRRuleForEveryMonthOnDay(startAt: DateTime) {
  return RRule.fromText(`Every month on the ${formatOrdinal(startAt.day)}`);
}

function getRRuleForEveryYearOnDay(startAt: DateTime) {
  return RRule.fromText(
    `Every year on ${startAt.toFormat('MMMM')} ${formatOrdinal(startAt.day)}`
  );
}

function recurrenceRulesToRRule(recurrenceRules?: string[]) {
  if (!recurrenceRules?.length) {
    return new RRule();
  }
  try {
    return rrulestr(recurrenceRules.join('\n'));
  } catch (error) {
    console.error(error);
    return new RRule();
  }
}
