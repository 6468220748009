import { useVisibleCalendars } from 'hooks/useVisibleCalendars';
import { useMemo } from 'react';
import { AvailableCalendar } from 'types/calendar';

export default function useAvailableCalendars(): AvailableCalendar[] {
  const calendars = useVisibleCalendars();

  const availableCalendars = useMemo(() => {
    return calendars.map((calendar) => ({
      id: calendar.email,
      name: calendar.displayName,
      color: calendar.colorFamily,
    }));
  }, [calendars]);

  return availableCalendars;
}

export function useColorForCalendarId(calendarId: string) {
  return useAvailableCalendars().find((calendar) => calendar.id === calendarId)
    ?.color;
}
