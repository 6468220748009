import { useVisibleEvents } from 'hooks/events/useGridEvents';
import useTimezone from 'hooks/useTimeZone';
import React, { useRef } from 'react';
import { IGridEvent } from 'types/events';
import { colorOrDefault } from 'utils/eventColors';
import IconEvent from '../icons/IconEvent';
import { HookProps, ListGroup } from '../types';
import { getTodaysUnfinishedEvents } from '../utils';
import { DateTime } from 'luxon';
import IconCalendar from 'components/Icons/IconCalendar';
import { useDefaultEventColor } from 'hooks/usePreferences';

export default function useTodaysEvents({ enabled }: HookProps): ListGroup {
  const events: IGridEvent[] = useVisibleEvents();
  const timezone = useTimezone();
  const currentDate = DateTime.now().setZone(timezone).toFormat('LLL dd');
  const defaultEventColor = useDefaultEventColor();

  const listGroupRef = useRef<ListGroup>({
    title: 'Today',
    subtitle: currentDate,
    items: [],
  });
  listGroupRef.current.items.length = 0;

  if (!enabled) return listGroupRef.current;

  const todaysUnfinishedEvents = getTodaysUnfinishedEvents(events);

  todaysUnfinishedEvents.forEach((event, index) => {
    if (index === 0) return; // skip the first item as it will be already shown in the Upcoming/Now section

    const color = event.colorFamily || defaultEventColor;

    listGroupRef.current.items.push({
      id: event.id,
      title: event.title,
      description: `${event.startAt.toFormat('HH:mm')} - ${event.endAt.toFormat(
        'HH:mm'
      )}`,
      type: 'event',
      Icon: () => (
        <IconCalendar
          className={`h-5 w-5 p-0.5 text-${color}-400 dark:text-${color}-400 text-opacity-75`}
        />
      ),
    });
  });

  return listGroupRef.current;
}
