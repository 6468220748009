import {
  getGridMouseCoordinates,
  __LowPerf__getGridInteractionHandlerBoundingRect as getGridBoundingRect,
} from 'components/Grid/clickHandler/GridInteractionsHandler';
import { generateEventUUID } from 'hooks/events/helpers/eventsHelpers';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useCalendarDays } from 'hooks/useCalendar';
import { useSetEventsSelection } from 'hooks/useEventsSelection';
import { useUpdateModal } from 'hooks/useModal';
import { atom } from 'jotai';
import { useAtomCallback, useAtomValue, useUpdateAtom } from 'jotai/utils';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DraggableType, DroppableId } from 'types/drag-and-drop';
import { ModalType } from 'types/modal';
import { coordinatesToTargetDate } from 'utils/mouseEvents';
import {
  clearDragAtom,
  dragAtom,
  dragCategoryAtom,
  dragEventAtom,
  dragOverAtom,
  dragOverCategoryAtom,
  dragOverTodoAtom,
  dragTodoAtom,
  optimisticTodosAtom,
} from './todosAtoms';
import { useUpdateTodos } from './useUpdateTodos';
import { categoryColorAtomFamily, categoryFamily, todoFamily } from './utils';

export const isDraggingCategoryAtom = atom((get) => {
  return get(dragCategoryAtom) && !get(dragTodoAtom);
});

const ALLOWED_TYPES: DraggableType[] = [
  DraggableType.TODO,
  DraggableType.TODO_CATEGORY,
  DraggableType.TODO_PLACEHOLDER,
];

export const isDraggingAtom = atom<boolean>((get) =>
  Boolean(get(dragTodoAtom) || get(dragCategoryAtom) || get(dragEventAtom))
);

const isMouseDownAtom = atom<boolean>(false);

export function useTodosDroppable(
  ref: MutableRefObject<HTMLDivElement | HTMLButtonElement | null>,
  id: string
) {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const onMouseEnter = useAtomCallback(
    useCallback(
      (get, set) => {
        if (get(isDraggingAtom)) {
          setIsDraggingOver(true);
          set(dragOverAtom, { id });
          set(dragOverTodoAtom, null);
          set(dragOverCategoryAtom, null);
        }
      },
      [id]
    )
  );

  const onMouseLeave = useAtomCallback(
    useCallback(
      (get, set) => {
        setIsDraggingOver(false);
        const dragOver = get(dragOverAtom);
        if (dragOver != null && dragOver.id === id) {
          set(dragOverAtom, null);
        }
      },
      [id]
    )
  );

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseenter', onMouseEnter);
      node.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      if (node) {
        node.removeEventListener('mouseenter', onMouseEnter);
        node.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, [onMouseEnter, onMouseLeave, ref]);

  return {
    isDraggingOver,
    setNodeRef: ref,
  };
}

export function useTodosDraggable<T extends HTMLElement>(
  id: string,
  type: DraggableType
) {
  const ref = useRef<T>(null);
  const handleRef = useRef<T>(null);

  const onDragStart = useAtomCallback(
    useCallback(
      (get, set) => {
        if (!ALLOWED_TYPES.includes(type)) {
          console.debug(`[Todos] Unknown todo drag type ${type}`);
          return;
        }

        if (type === DraggableType.TODO) {
          const dragTodo = get(todoFamily(id));
          if (dragTodo) {
            console.debug(
              `[Todos] Drag Start (Todo).\nSet drag todo to "${dragTodo.name}" (${dragTodo.id}))`
            );
            set(dragAtom, { id, type });
            set(dragTodoAtom, dragTodo);
          }
        } else if (type === DraggableType.TODO_CATEGORY) {
          const dragCategory = get(categoryFamily(id));
          if (dragCategory) {
            console.debug(
              `[Todos] Drag Start (Category).\nSet drag category to "${dragCategory?.name}" (${dragCategory.id})`
            );
            set(dragAtom, { id, type });
            set(dragCategoryAtom, dragCategory);
          }
        }
        if (ref.current) {
          ref.current.classList.add('opacity-30');

          const clear = () => {
            ref.current?.classList.remove('opacity-30');
            document.removeEventListener('mouseup', clear);
          };
          document.addEventListener('mouseup', clear);
        }
      },
      [id, type]
    )
  );

  const onDragOver = useAtomCallback(
    useCallback(
      (get, set) => {
        const { categories } = get(optimisticTodosAtom);

        const dragTodo = get(dragTodoAtom);
        const dragEvent = get(dragEventAtom);

        if (type === DraggableType.TODO) {
          if (dragTodo === null && dragEvent == null) return;
          const dragOverTodo = get(todoFamily(id));
          const dragOverCategory = dragOverTodo
            ? categories.find(
                (category) => category.id === dragOverTodo.categoryId
              )
            : undefined;
          if (dragOverTodo && dragOverCategory) {
            console.debug(
              `[Todos] Drag Over (Todo).\nSet drag over todo to ${dragOverTodo.name} (${dragOverTodo.id}).\nSet drag over category ${dragOverCategory.name} (${dragOverCategory.id})`
            );
            set(dragOverCategoryAtom, dragOverCategory);
            set(dragOverTodoAtom, dragOverTodo);
            set(dragOverAtom, {
              id,
              type,
            });
          } else {
            console.debug(dragOverTodo, dragOverCategory);
          }
        } else if (type === DraggableType.TODO_CATEGORY) {
          const dragCategory = get(dragCategoryAtom);

          if (id === dragCategory?.id) {
            // Do not set drag over category if you dragging over itself
            console.debug(
              `[Todos] Dragging category over itself.\nSet drag over category to null)`
            );
            set(dragOverCategoryAtom, null);
            set(dragOverAtom, null);
          } else {
            const dragOverCategory = categories.find(
              (category) => category.id === id
            );

            if (dragOverCategory) {
              console.debug(
                `[Todos] Drag Over (Category).\nSet drag over category "${dragOverCategory.name}" (${dragOverCategory.id})`
              );
              set(dragOverCategoryAtom, dragOverCategory);
              set(dragOverAtom, { id, type });
            }
          }
        } else if (type === DraggableType.TODO_PLACEHOLDER) {
          if (dragTodo != null || dragEvent != null) {
            console.debug(
              `[Todos] Dragging over category placeholder.\nSet drag over todo to null.`
            );
            set(dragOverTodoAtom, null);
            set(dragOverAtom, { id, type });
          }
        }
      },
      [id, type]
    )
  );

  const onDragHandleMouseDown = useAtomCallback(
    useCallback(() => {
      onDragStart();
    }, [onDragStart])
  );

  const onMouseEnter = useAtomCallback(
    useCallback(
      (get) => {
        if (get(isDraggingAtom)) {
          onDragOver();
        }
      },
      [onDragOver]
    )
  );

  useEffect(() => {
    const node = ref.current;
    const handle = handleRef.current;
    if (node) {
      node.addEventListener('mouseenter', onMouseEnter);
    }
    if (handle) {
      handle.addEventListener('mousedown', onDragHandleMouseDown);
    }

    return () => {
      if (node) {
        node.removeEventListener('mouseenter', onMouseEnter);
      }
      if (handle) {
        handle.removeEventListener('mousedown', onDragHandleMouseDown);
      }
    };
  }, [onMouseEnter, onDragHandleMouseDown]);

  return {
    setNodeRef: ref,
    setDragHandleRef: handleRef,
  };
}

export function useTodosDnd(): void {
  const isDragging = useAtomValue(isDraggingAtom);
  const { createDraftEvent } = useUpdateGridEvent();
  const days = useCalendarDays();

  const { selectEvent } = useSetEventsSelection();
  const { openModal } = useUpdateModal();
  useEffect(() => {
    if (isDragging) {
      document.body.classList.add('cursor-[grabbing]', 'select-none');
    } else {
      document.body.classList.remove('cursor-[grabbing]', 'select-none');
    }
  }, [isDragging]);

  const update = useUpdateTodos();

  const optUpdate = useUpdateAtom(optimisticTodosAtom);

  const clearDragAtoms = useUpdateAtom(clearDragAtom);

  const handleTodoDragEnd = useAtomCallback(
    useCallback(
      (get) => {
        const dragOverTodo = get(dragOverTodoAtom);
        const dragOverCategory = get(dragOverCategoryAtom);

        if (dragOverTodo) {
          const dragTodo = get(dragTodoAtom);

          if (!dragTodo) throw Error('Unable to drag todo item');
          if (!dragOverCategory)
            throw Error('Unable to find category for over todo item');
          // Dragging within category
          if (dragTodo?.categoryId === dragOverCategory?.id) {
            const currentIndex = dragOverCategory.todos.findIndex(
              (entry) => entry.id === dragTodo.id
            );

            const targetIndex = dragOverCategory.todos.findIndex(
              (entry) => entry.id === dragOverTodo.id
            );

            // When we move item up we need to use id of item above over for after id
            const afterIndex =
              targetIndex < currentIndex && targetIndex > 0
                ? targetIndex - 1
                : targetIndex;

            const objects = {
              todos: [
                {
                  id: dragTodo.id,
                  lastClientUpdate: new Date().toISOString(),
                  after:
                    targetIndex === 0
                      ? null
                      : dragOverCategory.todos[afterIndex].id,
                },
              ],
            };

            update(objects);
            optUpdate(objects);
            clearDragAtoms();
          }
          // Dragging between categories
          else if (dragOverCategory) {
            const dragOverIndex = dragOverCategory.todos.findIndex(
              (todo) => todo.id === dragOverTodo.id
            );

            const dragTodo = get(dragTodoAtom);

            if (!dragTodo) throw Error('Unable to drag todo item');

            const objects = {
              todos: [
                {
                  id: dragTodo.id,
                  lastClientUpdate: new Date().toISOString(),
                  after:
                    dragOverIndex > 0
                      ? dragOverCategory.todos[dragOverIndex - 1].id
                      : null,
                  categoryId: dragOverCategory.id,
                },
              ],
            };

            update(objects);
            optUpdate(objects);
            clearDragAtoms();
          }
        }
        // Drag over category, but not over todo (for empty categories, placeholders, etc)
        else if (dragOverCategory) {
          const dragTodo = get(dragTodoAtom);
          if (!dragTodo) throw Error('Unable to drag todo item');

          const len = dragOverCategory.todos.length;
          const objects = {
            todos: [
              {
                id: dragTodo.id,
                lastClientUpdate: new Date().toISOString(),
                after: len === 0 ? null : dragOverCategory.todos[len - 1].id,
                categoryId: dragOverCategory?.id,
              },
            ],
          };

          update(objects);
          optUpdate(objects);

          clearDragAtoms();
        } else {
          clearDragAtoms();
        }
      },
      [clearDragAtoms, optUpdate, update]
    )
  );

  const handleCategoryDragEnd = useAtomCallback(
    useCallback(
      (get) => {
        const active = get(dragCategoryAtom);
        const over = get(dragOverCategoryAtom);
        const { categories } = get(optimisticTodosAtom);
        if (over === null || active === null || active.id === over.id) {
          clearDragAtoms();
          return;
        }
        const currentIndex = categories.findIndex(
          (cat) => cat.id === active.id
        );
        const category = categories[currentIndex];

        if (currentIndex === -1 || !category) {
          clearDragAtoms();
          throw Error('Unable to find category for moved todo item');
        }

        const targetIndex = categories.findIndex(
          (entry) => entry.id === over.id
        );

        // When we move item up we need to use id of item above over for after id
        const afterIndex =
          targetIndex < currentIndex && targetIndex > 0
            ? targetIndex - 1
            : targetIndex;

        const objects = {
          categories: [
            {
              id: active.id,
              lastClientUpdate: new Date().toISOString(),
              after: targetIndex === 0 ? null : categories[afterIndex].id,
            },
          ],
        };

        update(objects);
        optUpdate(objects);
        clearDragAtoms();
      },
      [clearDragAtoms, optUpdate, update]
    )
  );

  const handleDragIntoSchedule = useAtomCallback(
    useCallback(
      (get) => {
        const dragOver = get(dragOverAtom);
        const dragTodo = get(dragTodoAtom);
        if (!dragTodo) throw new Error('Null drag todo when creating event');

        if (dragOver == null) {
          clearDragAtoms();
          return;
        }

        // Drag into schedule
        if (dragOver.id !== DroppableId.SCHEDULE) {
          clearDragAtoms();
          return;
        }

        const gridCoordinates = getGridMouseCoordinates();
        const gridDimensions = getGridBoundingRect();

        if (!gridDimensions) return;

        const pointDate = coordinatesToTargetDate({
          coordinates: gridCoordinates,
          height: gridDimensions?.height,
          width: gridDimensions?.width,
          roundToNearestQuarterHour: true,
          startDay: days[0].date,
          endDay: days[days.length - 1].date,
        });

        // Handle create event from todo
        // Draft event is created by TodoGhost
        if (!pointDate) {
          clearDragAtoms();
          return;
        }

        const colorFamily = get(categoryColorAtomFamily(dragTodo.categoryId));

        const newEventId = generateEventUUID();
        // Turn draft event into real event
        createDraftEvent({
          id: newEventId,
          title: dragTodo.name || 'New scheduled todo',
          doneAt: dragTodo.doneAt,
          colorFamily,
          startAt: pointDate.minus({ minutes: 15 }),
          endAt: pointDate.plus({
            minutes: 15,
          }),
        });

        selectEvent(newEventId);
        openModal(ModalType.Event);

        // Remove todo item from the schedule
        const objects = {
          todos: [
            {
              id: dragTodo.id,
              lastClientUpdate: new Date().toISOString(),
              deletedAt: new Date().toISOString(),
            },
          ],
        };
        optUpdate(objects);
        update(objects);

        // Clean up
        clearDragAtoms();
        return;
      },
      [
        days,
        createDraftEvent,
        selectEvent,
        openModal,
        optUpdate,
        update,
        clearDragAtoms,
      ]
    )
  );

  const onDragEnd = useAtomCallback(
    useCallback(
      (get) => {
        const dragItem = get(dragAtom);
        const dragOverItem = get(dragOverAtom);

        if (dragItem == null) return;
        switch (dragItem.type) {
          case DraggableType.TODO:
          case DraggableType.TODO_PLACEHOLDER:
          case DraggableType.EVENT: {
            if (dragOverItem?.id === DroppableId.SCHEDULE) {
              handleDragIntoSchedule();
              break;
            }
            handleTodoDragEnd();
            break;
          }
          case DraggableType.TODO_CATEGORY: {
            handleCategoryDragEnd();
            break;
          }
          default:
            return;
        }
      },
      [handleCategoryDragEnd, handleDragIntoSchedule, handleTodoDragEnd]
    )
  );

  const onMouseUp = useAtomCallback(
    useCallback(
      (_, set) => {
        set(isMouseDownAtom, false);
        onDragEnd();
      },
      [onDragEnd]
    )
  );

  const onMouseDown = useAtomCallback(
    useCallback((_, set) => {
      set(isMouseDownAtom, true);
    }, [])
  );

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, [onMouseDown, onMouseUp]);
}
